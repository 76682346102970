// Bibliographie sur l'histoire de Montréal (BHM) theme.

@use "sass:math";

@import "bootstrap_custom";
@import "uqam";
@import "print";

//
// BHM custom styles.
//

body {
  overflow-x: clip; // Needed for element introduced in .container-main::after.
}

// Buttons.
.btn-primary {
  // Override default btn-primary.
  @include button-variant($primary, $primary, $gray-900, $gray-900, $gray-900, $gray-900);
  color: $white;
}
.btn-light {
  // Override default btn-light.
  @include button-variant($light, $light, $primary, $primary, $primary, $primary);

  // Override color contrasts computed by button-variant().
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
  }
}
.btn-danger {
  // Override default btn-danger.
  @include button-variant($danger, $danger, $gray-900, $gray-900, $gray-900, $gray-900);
}

// Pagination.
.pagination {
  font-weight: bold;
}

// Navbar.
.navbar-brand {
  padding-top: 42px;
  padding-bottom: 42px;
}
.navbar {
  padding: 0 $navbar-padding-x * 3;
  margin: 0 math.div($grid-gutter-width, 2) * -1;
  background: $white url(./images/nav-bg.jpg) no-repeat;
  .logo {
    width: 270px;
    height: 56px;
    @include media-breakpoint-up(md) {
      width: 316px;
      height: 66px;
    }
  }
  .navbar-toggler {
    border: 0;
    &:focus {
      outline: 0;
    }
  }
  .navbar-collapse {
    padding: $nav-link-padding-y $nav-link-padding-x;
    border-bottom: 2px solid $gray-200;
    border-top: 2px solid $gray-200;
    margin-left: -3rem;
    margin-right: -3rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background: $white;
    @include media-breakpoint-up(lg) {
      border-bottom-width: 0;
      border-top-width: 0;
      background: transparent;
    }
  }
  .navbar-nav {
    // Necessary to get enough specificity for .nav-link styles.
    .nav-link {
      padding-left: $nav-link-padding-x;
      padding-right: $nav-link-padding-x;
      border-radius: $border-radius;
      margin-right: 0 !important;
      font-weight: bold;
      text-transform: uppercase;
      @include media-breakpoint-up(lg) {
        // margin-right: $nav-link-padding-x;
      }
      @include hover-focus-active {
        color: $white;
        background: $primary;
      }
    }
    .show {
      > .nav-link {
        color: $body-bg;
        background: $primary;
      }
    }
  }
}

// Facets and breadbox.
.facet-item-count {
  color: $secondary;
  font-size: 0.85em;
}
.breadbox,
.facets {
  a {
    color: $body-color;

    .fa-square {
      color: $secondary;
    }

    .fa-check-square {
      color: $primary;
    }

    &:hover {
      color: $link-hover-color;

      .fa-square,
      .fa-check-square {
        color: $link-hover-color;
      }
    }
  }
}

// Search results.
.search-results-list {
  border-top: $border-width solid $gray-200;
  border-bottom: $border-width solid $gray-200;

  .csl-entry {
    color: $body-color;

    .cite-authors {
      font-weight: bold;
      color: $secondary;
    }

    .cite-title {
      font-weight: bold;
      font-size: 1.25em;
    }

    &:hover {
      color: $link-hover-color;

      .cite-authors {
        color: $link-hover-color;
      }
    }
  }
}
.search-item {
  border-top: 1px dashed $gray-200;

  &:first-child {
    border-top: 0;
  }
}
.results-count {
  font-size: 1rem;
  color: $secondary;
}
.incontournable-result {
  font-size: 1.75em;
  margin-left: 0.5rem;
}

// Search item pages.
.search-item-single {
  padding-top: 2rem;
  border-top: $border-width solid $gray-200;

  & + .item-content {
    padding-bottom: 1rem;
    border-bottom: $border-width solid $gray-200;
  }
}
.item-content {
  overflow-x: hidden; // Prevent dashed border from overflowing.

  & > .row {
    border-top: 1px dashed $gray-200;
    padding-top: 0.75rem;

    &:nth-child(-n + 2) {
      border-top: 0;
      padding-top: unset;
    }

    &.mb-3 {
      margin-bottom: 0.75rem !important;
    }
  }
}

// Page.
.container-main {
  position: relative;
  padding-top: 0;
  background-color: $white;

  @media screen {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -610px;
      width: 610px;
      height: 707px;
      background-image: url("./images/bg1.jpg");
      background-repeat: no-repeat;
      background-position-x: right;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -599px;
      width: 599px;
      height: 707px;
      background-image: url("./images/bg2.jpg");
      background-repeat: no-repeat;
      background-position-x: left;
    }
  }
}
.main-inner {
  padding-top: 1.75rem;
  padding-bottom: 3rem;
  background-image: url(./images/content-top.png), url(./images/content-bottom.png);
  background-position:
    center top,
    center bottom;
  background-repeat: repeat-x;
}
